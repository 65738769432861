import { z } from "zod";

export enum MagicLinkTypes {
    clientSignUp = "clientSignUp",
}

export const magicLinkSchema = z.object({
    id: z.string().uuid(),
    createdBy: z.string().uuid(),
    type: z.nativeEnum(MagicLinkTypes),
    data: z.record(z.any()),
    code: z.string(),
    usedAt: z.coerce.date().nullish(),
    options: z.object({ affiliateUserId: z.string().uuid().optional() }).nullish(),
});

export type MagicLink = z.infer<typeof magicLinkSchema>;

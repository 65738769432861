import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import Select from "react-tailwindcss-select";
import { SelectValue } from "react-tailwindcss-select/dist/components/type";

export type Option = {
    value: string;
    label: string;
};

type Other = { options: Option[] };

export const SelectField = ({
    name,
    form,
    isRequired,
    label,
    ...other
}: FormFieldProps<string> & GetFieldPropsReturn<any> & Other) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || "",
    });
    const error = fieldState.error?.message;
    return (
        <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error}>
            <Select
                isSearchable
                primaryColor="white"
                {...field}
                value={other.options.find((o) => o.value === field.value) as SelectValue}
                onChange={(v) => {
                    field.onChange(v ? (Array.isArray(v) ? v.map((s) => s.value) : v.value) : undefined);
                }}
                options={other.options}
            />
        </Field>
    );
};
